<template>
  <article class="single-part">
    <div class="flex justify-between">
      <small class="code">
        <span>• LIBERATORIA N°</span>{{ el?.id || '---' }}<br/>
        <time>{{ el?.user_reward_assignment?.created_at || '---' }}</time>
      </small>
      <span class="rejected" v-if="el.rejected">
        Partecipazione respinta
      </span>
      <span class="approved" v-if="el.approved">
        Partecipazione approvata
      </span>
    </div>
    <section class="user">
      <div class="data">
        <div v-if="el?.first_name">
          <span>Nome:</span>
          {{ el?.first_name || '---' }}
        </div>
        <div v-if="el?.last_name">
          <span>Cognome:</span>
          {{ el?.last_name || '---' }}
        </div>
        <div v-if="el?.city">
          <span>Città:</span>
          {{ el?.city || '---' }}
        </div>
        <div v-if="el?.state">
          <span>Provincia:</span>
          {{ el?.state || '---' }}
        </div>
        <div v-if="el?.street">
          <span>Via:</span>
          {{ el?.street || '---' }}
        </div>
        <div v-if="el?.email_address">
          <span>Email:</span>
          {{ el?.email_address || '---' }}
        </div>
        <div v-if="el?.phone_number">
          <span>Numero di telefono:</span>
          {{ el?.phone_number || '---' }}
        </div>
        <div v-if="el?.doc_type">
          <span>Tipo di DOC:</span>
          {{ el?.doc_type || '---' }}
        </div>
        <div v-if="el?.doc_number">
          <span>Numero di DOC:</span>
          {{ el?.doc_number || '---' }}
        </div>
        <div v-if="el?.doc_expiration">
          <span>Data di scadenza DOC:</span>
          {{ el?.doc_expiration || '---' }}
        </div>
      </div>
      <div class="pics">
        <h6>Documento:</h6>
        <div v-if="img1">
          <small>- FRONTE</small>
          <a
            :href="img1"
            target="_blank"
          >⇓ SCARICA FRONTE
          </a>
        </div>
        <div v-if="img2">
          <small>- RETRO</small>
          <a
            :href="img2"
            target="_blank"
          >⇓ SCARICA RETRO
          </a>
        </div>
        <div v-if="!img1 && !img2">
          - Non pervenuto
        </div>
      </div>
    </section>
    <div
      class="accepted"
      v-if="el?.accept_prize === true"
    >
      ✓ PREMIO ACCETTATO
    </div>
    <div
      class="not-accepted"
      v-else-if="el?.accept_prize === false"
    >
      ✗ PREMIO RIFIUTATO
    </div>
    <hr />
    <div class="moderation-group" v-if="key === 0 && !el.rejected && !el.approved && img1 && img2">
      <button
        @click="openAlert(true)"
        class="moderation ok"
      >
        Approva la liberatoria
      </button>
      <button
        @click="openAlert(false)"
        class="moderation not-ok"
      >
        Rifiuta la liberatoria
      </button>
      <span class="confirm" v-if="showAlert">
        <i v-if="confirm === true">Confermi di voler approvare la liberatoria con id #{{ el?.id }}?</i>
        <i v-if="confirm === false">Confermi di voler rifiutare la liberatoria con id #{{ el?.id }}?</i>
        <textarea
          v-model="notes"
          placeholder="Motivazione del rifiuto"
          v-if="confirm === false"
          class="w-full my-2 p-2">
        </textarea>
        <button class="yes" @click="fireModeration">Conferma</button>
        <button class="no" @click="resetAlert">Annulla</button>
      </span>
    </div>
    <div v-else-if="img1 && img2">
      Partecipazione moderata correttamente.
    </div>
    <span class="confirm" v-else-if="!updated">
      <button class="complete" @click="toggleOverlay">✎ Completa i dati dell'utente</button>
    </span>
    <div v-else-if="updated">
      Dati della partecipazione aggiornati: ricaricare la pagina.
    </div>
    <div class="id" v-if="el?.id">
      #{{ el?.id }}
    </div>
    <!-- <ReleaseOverlay
      :partID="el?.id"
      :userData="userData"
      v-if="overlayOpen && el?.id"
      @closeOverlay="this.overlayOpen = false"
      @refreshStatus="updated = true"
    /> -->
  </article>
</template>

<script>
// import ReleaseOverlay from '@/components/ReleaseOverlay.vue'
export default {
  props: ['el'],
  // components: {
  //   ReleaseOverlay
  // },
  data () {
    return {
      showAlert: false,
      confirm: null,
      key: 0,
      img1: null,
      img2: null,
      updated: false,
      overlayOpen: false,
      notes: null
    }
  },
  computed: {
    userData () {
      const data = {
        terms_accepted: true,
        privacy1_accepted: true
      }
      if (this.el.extra_data.user_code) data.username = this.el.extra_data.user_code
      if (this.el.extra_data.email) data.email = this.el.extra_data.email
      if (this.el.extra_data.phone_number) data.phone_number = this.el.extra_data.phone_number
      if (this.el.item_number) data.played_code = this.el.item_number
      return data
    }
  },
  methods: {
    toggleOverlay () {
      this.overlayOpen = !this.overlayOpen
    },
    async getPartecipationImg (img) {
      if (img) {
        return await this.$store.dispatch('getOuterImage', img)
          .then((response) => {
            return URL.createObjectURL(response.data)
          })
          .catch((e) => {
            console.log('MISSING IMG', e)
          })
      }
    },
    async fireModeration () {
      const payload = {
        id: this.el.id,
        pay: {
          approved: null,
          rejected: null
        }
      }
      if (this.confirm === true) {
        payload.pay.approved = true
        payload.pay.rejected = false
      }
      if (this.confirm === false) {
        payload.pay.rejected = true
        payload.pay.approved = false
        if (this.notes) payload.pay.notes = this.notes
      }
      await this.$store.dispatch('updateRelease', payload)
        .then((rsp) => {
          if (rsp) {
            this.key++
            console.log('RSP', rsp)
          } else {
            const error = true
            const msg = 'ERRORE durante la conferma: ripetere la procedura.'
            this.emitter.emit('fireAlert', { msg, error })
          }
        })
    },
    resetAlert () {
      this.showAlert = false
      this.confirm = null
    },
    openAlert (bool) {
      this.showAlert = true
      this.confirm = bool
    }
    // getReleaseField (el, field) {
    //   let release = null
    //   if (el) release = el.tokens[0].played_token.partecipation.release
    //   if (field && release) return release[field]
    //   return release
    // }
  },
  async mounted () {
    const doc1 = this.el?.identity_document_urls?.identity_document_front
    const doc2 = this.el?.identity_document_urls?.identity_document_back
    // console.log('DOC1', doc1)
    // console.log('DOC2', doc2)
    if (doc1) {
      await this.getPartecipationImg(doc1)
        .then((rsp) => {
          this.img1 = rsp
        })
    }
    if (doc2) {
      await this.getPartecipationImg(doc2)
        .then((rsp) => {
          this.img2 = rsp
        })
    }
    // console.log('IMG1', this.img1)
    // console.log('IMG2', this.img2)
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/components/partecipation.scss";
</style>
