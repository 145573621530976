<template>
  <div class="page-view home">
    <div class="container">
      <div class="flex-wrapper">
        <div class="item">
          <h1 class="page-title"><v-icon name="bi-activity"/>Dashboard Conad Release Manager "Maestri del Taglio"</h1>
          <h3 class="subtitle">
            Elenco delle partecipazioni.<br/>
            Sulla destra vengono mostrate SOLO le partecipazioni che necessitano / hanno necessitato di moderazione, indi per cui solo eventuali vincite.
          </h3>
          <!-- <section class="stats" v-if="stats">
            <ul>
              <li
                v-for="(stat, index) in stats"
                :key="index"
              >
                {{stat.label}}&nbsp;:&nbsp;
                <b :class="{'changing' : this.animatedList.includes(stat.label)}">
                  {{stat.val}}
                </b>
              </li>
            </ul>
          </section> -->
        </div>
        <div class="item large">
          <ul class="panels">
            <li
              :class="{'selected': op === selectedOperator}"
              v-for="op in operators"
              :key="op"
              @click="selectOperator(op)"
            >
              Operatore {{ op }}
              <img src="@/assets/img/click.svg" />
            </li>
          </ul>
          <h5>—Registro completo:</h5>
          <div v-if="loading2" class="loading animate-pulse">CARICAMENTO ...</div>
          <div v-else-if="getHistory && getHistory.length > 0">
            <ul class="partecipation-list">
              <li v-for="(el, index) in getHistory" :key="index">
                <!-- {{el}} -->
                <Partecipation :el="el" />
              </li>
            </ul>
            <div class="text-center">
              <button
                class="load-more"
                @click="loadMoreParts"
                v-if="(history.length >= (counter * queryLimit)) && !loading3"
              >
                Carica altre partecipazioni
              </button>
              <div v-else-if="loading3" class="loading animate-pulse">CARICAMENTO ...</div>
            </div>
          </div>
          <div class="text-sm mt-4 text-red-600" v-else-if="!getHistory">
            Errore durante il recupero delle informazioni.
          </div>
          <div class="text-sm mt-4" v-else-if="getHistory.length === 0">
            Non ci sono nuove partecipazioni in questo momento.
          </div>
        </div>
      </div>
    </div>
    <img v-if="!history" class="bg-image" src="@/assets/img/dashboard.svg" />
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout
      <b>{{stateMail}}</b>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { Field, Form, ErrorMessage } from 'vee-validate'
import Partecipation from '@/components/Partecipation.vue'
import toISOString from '@/utils/toISOString.js'

const divider = 250

export default {
  name: 'Home',
  components: {
    Partecipation
  },
  data () {
    return {
      operators: 4,
      selectedOperator: null,
      interval: null,
      loading: false,
      loading2: true,
      loading3: false,
      history: null,
      stats: null,
      whatToExport: 'all',
      counter: 0,
      queryLimit: 18,
      categories: [
        'in',
        'user_create',
        'item_created',
        'partecipation_created_lost',
        'partecipation_created_win',
        'sent',
        'delivered',
        'blocked_max_part',
        'error'
      ],
      testQuery: this.$route.query.test,
      animatedList: [],
      tablePaging: 0
    }
  },
  computed: {
    ...mapGetters([
      'stateMail'
    ]),
    getHistory () {
      const history = this.history
      // if (history && history.length > 0) {
      //   history = history.filter((el) => {
      //     return el.tokens[0].played_token.partecipation.result === 'win'
      //   })
      // }
      return history
    }
  },
  methods: {
    toISOString,
    selectOperator (id) {
      if (id === this.selectedOperator) this.selectedOperator = null
      else this.selectedOperator = id
    },
    async loadMoreParts () {
      this.counter++
      this.loading3 = true
      let counterResult = this.counter * this.queryLimit
      if (this.selectedOperator) {
        counterResult += ((this.selectedOperator - 1) * divider)
      }
      const payload = {
        off: counterResult,
        lim: this.queryLimit
      }
      await this.$store.dispatch('getReleases', payload)
        .then((rsp) => {
          console.log('ITEMS', rsp)
          this.history = this.history.concat(rsp)
        })
      this.loading3 = false
    },
    async getItems () {
      let counterResult = this.counter
      if (this.selectedOperator) {
        counterResult = (this.selectedOperator - 1) * divider
      }
      const payload = {
        off: counterResult,
        lim: this.queryLimit
      }
      await this.$store.dispatch('getReleases', payload)
        .then((rsp) => {
          console.log('ITEMS', rsp)
          this.history = rsp
        })
    },
    async getStats (animated) {
      this.animatedList = []
      if (!animated) this.loading = true
      await this.$store.dispatch('getStats')
        .then((rsp) => {
          const temp = []
          for (let i = 0; i < Object.keys(rsp).length; i++) {
            const lbl = Object.keys(rsp)[i]
            const val = Object.values(rsp)[i]
            const translated = {
              label: null,
              val
            }
            switch (lbl) {
              case 'users_count':
                translated.label = 'Totale utenti'
                break
              case 'users_count_active':
                translated.label = 'Totale utenti attivi'
                break
              case 'user_reward_assignments_count':
                translated.label = 'Totale premi assegnati'
                break
              case 'items_count':
                translated.label = 'Totale SMS validi'
                break
              case 'received_sms_count':
                translated.label = 'SMS ricevuti'
                break
              case 'sms_sent':
                translated.label = 'SMS inviati'
                break
              case 'partecipation_count':
                translated.label = 'Totale partecipazioni'
                break
              case 'received_whatsapp_count':
                translated.label = 'Whatsapp ricevuti'
                break
              case 'whatsapp_sent':
                translated.label = 'Whatsapp inviati'
                break
              case 'whatsapp_error_messagges_sent':
                translated.label = 'Whatsapp ERRORE inviati'
                break
              case 'sms_error_messagges_sent':
                translated.label = 'SMS ERRORE inviati'
                break
              case 'items_not_moderated_count':
                translated.label = 'Partecipazioni NON moderate'
                break
              case 'items_not_rejected_count':
                translated.label = 'Partecipazioni NON rifiutate'
                break
              case 'items_rejected_count':
                translated.label = 'Partecipazioni rifiutate'
                break
              default:
                translated.label = lbl
            }
            if (translated.label !== 'Totale SMS validi') temp.push(translated)
            temp.sort(function (a, b) {
              return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0
            })
          }
          if (animated) {
            for (let i = 0; i < temp.length; i++) {
              if (!this.stats || !this.stats[i] || this.stats[i].val !== temp[i].val) {
                this.animatedList.push(temp[i].label)
              }
            }
            // console.log('TO ANIMATE', this.animatedList)
          }
          this.stats = temp
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
        .catch((e) => {
          console.error('GOING TO LOGOUT', e)
          this.$router.push('/login')
        })
      if (!this.stats) this.$router.push('/login')
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    }
  },
  watch: {
    $route (to, from) {
      clearInterval(this.interval)
    },
    async selectedOperator () {
      this.loading2 = true
      this.history = null
      this.counter = 0
      await this.getItems()
      this.loading2 = false
    }
  },
  async mounted () {
    // console.log('---STORE', this.$store.state)
    await this.getItems()
    this.loading2 = false
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
</style>
